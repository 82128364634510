import Cookies from 'js-cookie';
import { localStorageCheck } from '../utils/localStorageCheck';
import getQueryParams from '../utils/getQueryParameters';

export default function () {
    const GOOGLE_ORGANIC_SEARCH_ENGINES = [
        /\.google\./,
        /\.bing\./,
        /\.yahoo\./,
        /\.ask\./,
        /^(?!.*mail\.aol\.)(?=.*\.aol\.).*$/,
        /isearch\.avg/,
        /search\.babylon\./,
        /search\.centrum\.cz/,
        /search\.conduit\./,
        /duckduckgo/,
        /\.ecosia\./,
        /\.eniro\./,
        /\.lycos\./,
        /\.kvasir\./,
        /\.msn\./,
        /\.najdi\./,
        /szukaj\./,
        /\.qwant\./,
        /\.seznam\./,
        /\.startsiden\./,
        /\.ukr\./,
        /\.virgilio\./,
        /\.wp\./,
        /yandex\./,
    ];

    const COOKIE_OPTIONS = {
        path: '/',
        domain: window.location.hostname,
        expires: 8, // Facebook and Adwords are having a 8 days lead attribution logic.
    };

    const PERSONIO_CHANNEL_ID_DIRECT = 1001;
    const PERSONIO_CHANNEL_ID_ORGANIC = 628869;

    const queryParams = getQueryParams();

    const isCookieUndefinedOrNone = (cookieName) => {
        const cookie = Cookies.get(cookieName);
        return cookie === undefined || cookie === 'none';
    };

    /**
     * We need this check to prevent the cookie to be overwritten on each page load.
     */
    const isRecruitingChannelIdSessionStarted = () => sessionStorage.getItem(
        'recruitingChannelIdSessionStarted',
    ) === 'true';

    const buildRecruitingChannelId = () => {
        /**
         * Case where a recruiting channel ID would be passed through the URL.
         */
        if (queryParams.recruiting_channel_id) {
            return queryParams.recruiting_channel_id;
        }

        /**
         * Or is it a "direct" recruiting channel ID ?
         */
        if (
            isCookieUndefinedOrNone('thermondo__medium__last_visit')
            && isCookieUndefinedOrNone('thermondo__page_referrer__last_visit')
            && !isCookieUndefinedOrNone('thermondo__page_landing__last_visit')
            && isCookieUndefinedOrNone('google_analytics__gclid')
        ) {
            return PERSONIO_CHANNEL_ID_DIRECT;
        }

        /**
         * Or is it an "organic" recruiting channel ID ?
         */
        if (
            isCookieUndefinedOrNone('thermondo__medium__last_visit')
            && isCookieUndefinedOrNone('google_analytics__gclid')
            && GOOGLE_ORGANIC_SEARCH_ENGINES.some((rule) => {
                const re = new RegExp(rule);
                return re.test(Cookies.get('thermondo__page_referrer__last_visit'));
            })
        ) {
            return PERSONIO_CHANNEL_ID_ORGANIC;
        }

        /**
         * No recruiting channel ID has been built.
         */
        return false;
    };

    const handleRecruitingChannelIdCookie = () => {
        /**
         * This cookie, if set, will be collected by the job application form and sent along the
         * applicant data to Personio.
         * The advantage of having this value set as a cookie is to not lose it if the applicant
         * visits different pages, or come back later before to submit an application.
         */
        sessionStorage.setItem('recruitingChannelIdSessionStarted', true);

        /**
         * Create, update or remove "recruitingChannelId" cookie.
         */
        const recruitingChannelId = buildRecruitingChannelId();

        if (recruitingChannelId) {
            Cookies.set(
                'recruitingChannelId',
                recruitingChannelId,
                COOKIE_OPTIONS,
            );
        } else {
            Cookies.remove('recruitingChannelId');
        }
    };

    if (localStorageCheck.isEnabled() && !isRecruitingChannelIdSessionStarted()) {
        handleRecruitingChannelIdCookie();
    }
}
