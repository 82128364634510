const titleTemplate = `
    {{ @if(it.key === "heat_pump") }}
        Wärmepumpe: die effiziente Klimaheizung
    {{ /if }}

    {{ @if(it.key === "natural_gas") }}
        Effiziente Technologie bereit für mehr
    {{ /if }}

    {{ @if(it.key === "solar") }}
        Effizient & ökologisch heizen mit Solarthermie
    {{ /if }}
`;

const textTemplate = `
    {{ @if(it.key === "heat_pump") }}
        Die Wärmepumpe nutzt effizient Umgebungswärme und macht Ihre Wärme so klimaneutral.
    {{ /if }}

    {{ @if(it.key === "natural_gas") }}
        Moderne Gas-Brennwertkessel sind bis zu 30 % effizienter als konventionelle Gasheizungen. Viele Modelle lassen sich mit Solarthermie-Modulen verbinden.
    {{ /if }}

    {{ @if(it.key === "solar") }}
        Nutzen Sie mit Solarthermie kostenlose Sonnenenergie für Heizung und Warmwasser. Der Staat belohnt das mit bis zu 25 % Förderung!
    {{ /if }}
`;

const linkLabelTemplate = `
    {{ @if(it.key === "heat_pump") }}
        Mehr über die Wärmepumpe
    {{ /if }}

    {{ @if(it.key === "natural_gas") }}
        Mehr über Gas-Brennwertkessel
    {{ /if }}

    {{ @if(it.key === "solar") }}
        Mehr über Solarthermie
    {{ /if }}
`;

const linkTargetTemplate = `
    {{ @if(it.key === "heat_pump") }}
        /leistungen/heizsystem/waermepumpe/
    {{ /if }}

    {{ @if(it.key === "natural_gas") }}
        /leistungen/heizsystem/gasheizung/
    {{ /if }}

    {{ @if(it.key === "solar") }}
        /leistungen/heizsystem/solarthermie/
    {{ /if }}
`;

export {
    titleTemplate,
    textTemplate,
    linkLabelTemplate,
    linkTargetTemplate,
};
