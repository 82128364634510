/**
 * Check if sessionStorage is available/usable (inspired from Modernizr field-tested approach).
 * @returns {boolean}
 */
const sessionStorageEnabled = () => {
    try {
        const val = 'check';
        sessionStorage.setItem(val, val);
        sessionStorage.removeItem(val);
        return true;
    } catch (error) {
        return false;
    }
};

// eslint-disable-next-line
export { sessionStorageEnabled };
