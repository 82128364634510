import Plyr from 'plyr';
import { captureMessage, withScope } from '@sentry/browser';

export default class VideoPlayerHandler {
    constructor() {
        this.init();
    }

    handleError(error) {
        withScope((scope) => {
            if (error && error.detail) {
                const { message } = error.detail;
                scope.setExtra('message', message);
            }

            captureMessage('plyr error');
        });
    }

    init() {
        this.plyrInstances = Array.from(document.querySelectorAll('[data-video-player]')).map((player) => {
            const plyrInstance = new Plyr(player, this.setPlayerOptions(player));
            plyrInstance.on('error', this.handleError);

            this.pauseAllVideos(plyrInstance);
            return plyrInstance;
        });
    }

    setPlayerOptions(player) {
        /**
         * Plyr options can be configured in the template using data attributes.
         *
         * Available options:
         * data-video-player-autoplay
         * data-video-player-loop
         * data-video-player-muted
         * data-video-player-custom-controls
         * data-video-player-controls
         * data-video-player-fullscreen
         * data-video-player-ratio
         *
         * If not set, the default values are Plyr's default values.
         * See https://github.com/sampotts/plyr#javascript
         */
        const {
            videoPlayerAutoplay,
            videoPlayerLoop,
            videoPlayerMuted,
            videoPlayerCustomControls,
            videoPlayerControls,
            videoPlayerFullscreen,
            videoPlayerRatio,
        } = player.dataset;

        return {
            iconUrl: '/static/images/blocks/plyr.svg',
            fullscreen: { enabled: videoPlayerFullscreen === 'true' },
            ratio: videoPlayerRatio || '16:10',
            youtube: {
                noCookie: true,
            },
            vimeo: {
                speed: false,
                dnt: true,
                muted: videoPlayerMuted === 'true',
                autoplay: videoPlayerAutoplay === 'true',
                loop: videoPlayerLoop === 'true',
                controls: videoPlayerControls === 'true',
                customControls: videoPlayerCustomControls === 'true',
            },
        };
    }

    pauseAllVideos(activePlyrInstance) {
        // More information about Plyr methods: https://github.com/sampotts/plyr#methods
        activePlyrInstance.on('play', () => {
            this.plyrInstances.forEach((plyrInstance) => {
                if (activePlyrInstance !== plyrInstance) {
                    plyrInstance.pause();
                }
            });
        });
    }
}
