import getQueryParams from '../utils/getQueryParameters';

export default function () {
    const block = document.querySelector('[data-customer-referral-result]');

    const { referral_id: referralId } = getQueryParams();
    if (!referralId) {
        block.classList.add('customer-referral-result--invalid');
        return;
    }

    // Set referral link as input value
    const input = block.querySelector('.customer-referral-result__input');
    const referralLink = `${input.value}?referral_id=${referralId}`;
    input.value = referralLink;

    // Copy referral link to clipboard when clicking the copy button
    const copyButton = block.querySelector('.customer-referral-result__button');
    copyButton.addEventListener('click', async () => {
        await navigator.clipboard.writeText(referralLink);
        copyButton.innerHTML = '<i class="t-website-check"></i> Kopiert!';
        copyButton.setAttribute('disabled', '');
    });

    const messages = {
        default: 'Hallo, du interessierst dich für eine neue Heizung. Aktuell kannst du dir nämlich bis zu 70 % Förderzuschüsse sichern und sparst damit deutlich an Investitionskosten. thermondo hat genau die passende Heizlösung für dich. Über diesen Link hier erhältst du ein kostenloses Festpreisangebot und zusätzlich nach Installation eine 300 € Prämie.',
        mail: `Hallo,\n\nfalls auch du einen Heizungswechsel in Betracht ziehst, kann ich dir diese Firma wärmstens empfehlen! thermondo ist Deutschlands größter Heizungsbauer, mit entsprechend großer Erfahrung im Austausch von Heizungen. Von der Planung über Fördermittelservice bis hin zur Installation übernimmt thermondo alle notwendigen Schritte.\n\nÜbrigens, du kannst dir aktuell bis zu 70 % Förderzuschüsse sichern und sparst damit deutlich an Investitionskosten.\n\nMit dem thermondo-Empfehlungsprogramm erhalten wir beide nach erfolgreicher Installation deiner neuen Heizung eine Geldprämie von 300 Euro. Folge einfach meiner Empfehlung und nutze den folgenden Link: ${referralLink}\n\nWichtig: Damit ein Prämienanspruch besteht, muss deine Anfrage über den oben stehenden Empfehlungslink erfolgen!\n\nMit besten Grüßen`,
    };

    // Add referral link to all sharable hrefs
    const shareableList = block.querySelectorAll('.customer-referral-result__shareable');

    shareableList.forEach((shareable) => {
        const { baseHref, utmSource } = shareable.dataset;
        let shareableMessage;
        let messageType = 'default';

        if (shareable.classList.contains('button-mail')) {
            messageType = 'mail';
            shareableMessage = encodeURIComponent(messages.mail);
        } else shareableMessage = encodeURIComponent(messages.default);
        shareable.setAttribute(
            'href',
            `${baseHref}${messageType === 'default' ? referralLink : ''}${shareableMessage}&amp;utm_source=${utmSource}&amp;utm_medium=share`,
        );
    });
}
