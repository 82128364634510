import axios from 'axios';
import Cookies from 'js-cookie';
import { captureException, withScope } from '@sentry/browser';

export default class DailyLeads {
    constructor() {
        this.STATISTICS_ENDPOINT = `${window.TNS.config.backend.url}/api/customer/statistic/`;
        this.TOKEN = window.TNS.config.backend.token;
        this.COOKIE_NAME = 'daily-leads';

        const cookieLeads = Cookies.get(this.COOKIE_NAME);
        if (cookieLeads) {
            this.updateDailyLeadsDigits(cookieLeads);
        } else {
            this.load();
        }
    }

    load() {
        const headers = {
            Authorization: `Token ${this.TOKEN}`,
        };

        return axios.get(
            this.STATISTICS_ENDPOINT,
            {
                headers: {
                    ...headers,
                },
            },
        ).then(({ data }) => {
            const leads = data.created_today.toString();
            this.updateDailyLeadsDigits(leads);

            const inOneMinute = new Date(new Date().getTime() + 60 * 1000);
            Cookies.set(this.COOKIE_NAME, leads, {
                expires: inOneMinute,
            });
        }).catch(() => {
            // Silently fail
        });;
    }

    updateDailyLeadsDigits(digits) {
        const digitElemInsertStartIndex = 4 - digits.length;
        const dailyLeadsElems = document.querySelectorAll('[data-daily-leads]');

        dailyLeadsElems.forEach((dailyLeadsElem) => {
            const el = dailyLeadsElem;

            const dailyLeadsElemItems = el.querySelectorAll('.daily-leads__digit');
            let digitIndex = 0;

            dailyLeadsElemItems.forEach(
                (digitElem, index) => {
                    if (index >= digitElemInsertStartIndex) {
                        // eslint-disable-next-line no-param-reassign
                        digitElem.innerHTML = digits[digitIndex];
                        digitIndex += 1;
                    }
                },
            );

            el.style.display = 'block';
        });
    }
}
