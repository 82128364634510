import Cookies from 'js-cookie';
import MicroModal from 'micromodal';
import { scrollTo } from 'scroll-js';
import { captureException } from '@sentry/browser';
import validate from 'validate.js';
import { localStorageCheck } from '../utils/localStorageCheck';
import { eT } from './EventTracking';

export default class JobForm {
    constructor() {
        this.API_URL = 'https://europe-west3-thermondo-homepage.cloudfunctions.net';
        this.API_HEADERS = {
            Accept: 'application/json',
        };

        this.form = document.getElementById('job_form');

        this.form.addEventListener(
            'submit',
            (ev) => {
                ev.preventDefault();
                this.handleFormSubmit(this.form);
            },
        );

        this.inputs = this.form.querySelectorAll(
            'input:not([type="hidden"]):not([type="file"])',
        );

        this.inputs.forEach((input) => {
            input.addEventListener('change', () => {
                const errors = validate(this.form, this.constraints) || {};
                this.handleClientErrorMessagesDisplay(input, errors[input.name]);
            });
        });

        this.submitBtn = this.form.querySelector(
            'button[type="submit"]',
        );

        this.uploadBoxEl = this.form.querySelector(
            '[data-job-form-upload-box]',
        );

        this.uploadBoxInputEl = this.uploadBoxEl.querySelector(
            'input[type=file]',
        );

        this.uploadBoxBtnEl = this.uploadBoxEl.querySelector(
            '[data-job-form-upload-btn]',
        );

        this.uploadBoxStatusAmountEl = this.uploadBoxEl.querySelector(
            '[data-job-form-upload-box-status-amount]',
        );

        this.uploadBoxStatusFilesListEl = this.uploadBoxEl.querySelector(
            '[data-job-form-upload-box-files-list]',
        );

        this.formInfoBoxServerErrorGlobal = this.form.querySelector(
            '[data-job-form-server-error-global]',
        );

        this.constraints = {
            first_name: {
                presence: true,
            },
            last_name: {
                presence: true,
            },
            email: {
                presence: true,
                email: true,
            },
            phone: {
                presence: true,
            },
            terms: {
                inclusion: {
                    within: [true],
                },
            },
        };

        this.payloadFiles = [];

        this.init();
    }

    init() {
        this.uploadBoxInputEl.addEventListener(
            'change',
            () => {
                this.updateSelectedFilesAmount();
                this.clearFilesList();
                this.uploadFiles();
                this.updateFilesButtonText();
            },
        );
    }

    async uploadFiles() {
        const url = `${this.API_URL}/personio-upload-document`;
        const filesArray = Array.from(this.uploadBoxInputEl.files);

        this.payloadFiles = [];
        this.submitBtn.disabled = true;

        await Promise.all(filesArray.map(async (file) => {
            const fileEl = document.createElement('div');
            const fileLabelEl = document.createElement('div');
            const fileStatusEl = document.createElement('div');

            fileEl.classList.add('job-form__files-list__item');
            fileLabelEl.classList.add('job-form__files-list__label');
            fileStatusEl.classList.add('job-form__files-list__status');
            fileLabelEl.innerText = file.name;

            fileEl.append(fileLabelEl);
            fileEl.append(fileStatusEl);
            this.uploadBoxStatusFilesListEl.append(fileEl);

            try {
                const formData = new FormData();
                const options = {
                    method: 'POST',
                    body: formData,
                    headers: this.API_HEADERS,
                };

                formData.append('file', file);

                fileStatusEl.classList.add('job-form__files-list__status--pending');

                const response = await fetch(url, options);

                fileStatusEl.classList.remove('job-form__files-list__status--pending');

                if (!response.ok) {
                    const errorData = await response.json();
                    fileStatusEl.innerText = `Fehler (${errorData.error.reason})`;
                    fileStatusEl.classList.add('job-form__files-list__status--error');
                    return;
                }

                const responseData = await response.json();

                this.payloadFiles.push({
                    uuid: responseData.uuid,
                    original_filename: responseData.original_filename,
                    category: 'other',
                });

                fileStatusEl.innerText = 'Hochgeladen';
                fileStatusEl.classList.add('job-form__files-list__status--success');
            } catch (error) {
                captureException(
                    new Error(
                        `Error during file upload (${file.name}): ${error.message}`,
                    ),
                );
            }
        }));

        this.submitBtn.disabled = false;
    }

    updateSelectedFilesAmount() {
        this.uploadBoxStatusAmountEl.innerText = `
            ${this.uploadBoxInputEl.files.length}
            Datei${this.uploadBoxInputEl.files.length > 1 ? 'en' : ''} ausgewählt:
        `;
    }

    clearFilesList() {
        this.uploadBoxStatusFilesListEl.style.display = 'block';
        this.uploadBoxStatusFilesListEl.innerText = '';
    }

    updateFilesButtonText() {
        this.uploadBoxBtnEl.innerText = this.uploadBoxInputEl.files.length > 0
            ? 'Datei(en) ändern'
            : 'Datei(en) auswählen';
    }

    preparePayload() {
        const payload = {};

        payload.job_position_id = this.form.querySelector('[name="job_position_id"]').value;
        payload.first_name = this.form.querySelector('[name="first_name"]').value;
        payload.last_name = this.form.querySelector('[name="last_name"]').value;
        payload.email = this.form.querySelector('[name="email"]').value;
        payload.attributes = [
            {
                id: 'phone',
                value: this.form.querySelector('[name="phone"]').value,
            },
        ];
        payload.files = this.payloadFiles;

        if (localStorageCheck.isEnabled()) {
            const recruitingChannelId = Cookies.get('recruitingChannelId');
            if (recruitingChannelId) {
                payload.recruiting_channel_id = recruitingChannelId;
            }
        }

        return payload;
    }

    async handleFormSubmit() {
        const errors = validate(this.form, this.constraints);
        this.checkForErrors(errors || {});

        if (errors) {
            this.scrollToFirstError();
            return;
        }

        this.isLoading(true);
        this.handleServerErrorMessageDisplay(false);

        try {
            const response = await fetch(`${this.API_URL}/personio-upload-application`, {
                method: 'POST',
                headers: {
                    ...this.API_HEADERS,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(this.preparePayload()),
            });

            if (!response.ok) {
                const errorData = await response.json();

                if (errorData.error) {
                    this.handleServerErrorMessageDisplay(true, errorData.error.reason);
                }

                if (errorData.errors) {
                    this.handleServerErrorMessageDisplay(
                        true,
                        JSON.stringify(errorData.errors),
                    );
                }

                this.isLoading(false);
                return;
            }

            this.clearForm();
            this.showServerSuccessMessage();
            eT({
                event: 'job_conversion',
            });
        } catch (error) {
            this.handleServerErrorMessageDisplay(true);
            captureException(new Error(`JobForm: ${JSON.stringify(error)}`));
        }

        this.isLoading(false);
    }

    scrollToFirstError() {
        const firstErrorEl = document.getElementsByClassName('form-field-error__container')[0];

        if (firstErrorEl) {
            scrollTo(
                document.body,
                {
                    behavior: 'smooth',
                    top: firstErrorEl.offsetTop - 100,
                },
            );
        }
    }

    clearForm() {
        // Clear file input
        this.uploadBoxInputEl.value = '';
        const event = new Event('change');
        this.uploadBoxInputEl.dispatchEvent(event);

        // Clear text inputs
        this.inputs.forEach((input) => {
            // eslint-disable-next-line no-param-reassign
            input.value = '';
        });
    }

    isLoading(state) {
        if (state) {
            this.submitBtn.classList.add('t-btn-is-loading');
        } else {
            this.submitBtn.classList.remove('t-btn-is-loading');
        }

        this.submitBtn.disabled = state;
    }

    handleClientErrorMessagesDisplay(input, hasError) {
        const parentEl = input.parentNode.parentNode;

        if (hasError) {
            parentEl.classList.add('form-field-error__container');
        } else {
            parentEl.classList.remove('form-field-error__container');
        }
    }

    handleServerErrorMessageDisplay(hasError, errorMessage) {
        this.formInfoBoxServerErrorGlobal.style.display = hasError ? 'block' : 'none';
        this.formInfoBoxServerErrorGlobal.querySelector('.form-info-box__error-message')
            .innerText = errorMessage || '';
    }

    showServerSuccessMessage() {
        MicroModal.show('modal-job-form-success');
    }

    checkForErrors(errors) {
        this.inputs.forEach((input) => {
            this.handleClientErrorMessagesDisplay(input, errors[input.name]);
        });
    }
}
