import { captureException } from '@sentry/browser';

export default function () {
    const btns = [...document.querySelectorAll('[data-usercentrics-showpanel]')];
    btns.forEach((el) => {
        el.addEventListener(
            'click',
            (e) => {
                e.preventDefault();
                if (
                    Object.prototype.hasOwnProperty.call(
                        window,
                        'UC_UI',
                    )
                ) {
                    window.UC_UI.showSecondLayer();
                } else {
                    captureException(new Error('Error: "window.UC_UI.showSecondLayer()" doesn\'t exist.'));
                }
            },
        );
    });
}
