import { scrollTo } from 'scroll-js';

const removeActiveClass = (wrap, className) => {
    const contents = wrap.querySelectorAll(`.${className}`);
    contents.forEach((el) => el.classList.remove(className));
};

const handleItemClick = async (wrap, item) => {
    const LG_UP = 1024;
    const HEADER_HEIGHT_LG_UP = 96;
    const HEADER_HEIGHT_LG_DOWN = 48;

    // Update tab item class
    removeActiveClass(wrap, 'tabs__item--active');
    item.classList.add('tabs__item--active');

    // Center active tab item
    const itemWidth = item.offsetWidth;
    const wrapWidth = item.parentNode.offsetWidth;
    item.parentNode.scrollLeft = item.offsetLeft - (wrapWidth / 2) + (itemWidth / 2);

    // Scroll to beginning of content
    await scrollTo(
        document.body,
        {
            behavior: 'smooth',
            top: wrap.offsetTop - (window.innerWidth
            >= LG_UP ? HEADER_HEIGHT_LG_UP : HEADER_HEIGHT_LG_DOWN),
        },
    );

    // Update tab content active class
    removeActiveClass(wrap, 'tabs__content--active');
    const content = wrap.querySelector(`[data-tabs-content=${item.dataset.tabsItem}]`);
    content.classList.add('tabs__content--active');
};

const tabs = () => {
    const wrap = document.querySelector('.tabs__wrap');
    if (wrap) {
        const items = wrap.querySelectorAll('[data-tabs-item]');
        items.forEach((item) => {
            item.addEventListener(
                'click',
                () => handleItemClick(wrap, item),
            );
        });
    }
};

export default tabs;
