/**
 * This approach is based on how VWO is integrated in Google Tag Manager:
 * https://help.vwo.com/hc/en-us/articles/900005594183-Integrating-VWO-With-GA4-via-Google-Tag-Manager
*/

import Cookies from 'js-cookie';
import { localStorageCheck } from './localStorageCheck';

/*
    Gets the variation ID, which is either in the cookie or window object,
    depending on what type of campaign (split or ab).
 */
const getVwoVariationId = (vwoExp, campaignId) => {
    let variationId = Cookies.get(`_vis_opt_exp_${campaignId}_combi`);

    if (typeof vwoExp[campaignId].combination_chosen !== 'undefined') {
        variationId = vwoExp[campaignId].combination_chosen;
    }

    return variationId;
};

/*
    Returns an array of all active VWO campaign and variation IDs
 */
const getVwoIds = () => {
    const ids = [];

    const { _vwo_exp_ids: vwoExpIds, _vwo_exp: vwoExp } = window;
    if (vwoExpIds && vwoExp) {
        vwoExpIds.forEach((id) => {
            // VWO sets ready=true for running campaigns
            if (vwoExp[id] && vwoExp[id].ready) {
                const variationId = getVwoVariationId(vwoExp, id);
                if (typeof variationId !== 'undefined') {
                    ids.push({
                        campaignId: id,
                        variationId,
                    });
                }
            }
        });
    }

    return ids;
};

/*
    Updates the session storage to include active VWO campaigns
 */
const updateSession = () => {
    const { abTestSessionInitialised } = window;
    if (abTestSessionInitialised === true) {
        return;
    }

    const key = 'ab_test_session';
    let session = sessionStorage.getItem(key);
    if (!session) {
        session = [];
    } else {
        session = JSON.parse(session);
    }

    const ids = getVwoIds();
    if (ids.length > 0) {
        session.push(...ids);
        sessionStorage.setItem(key, JSON.stringify(session));
        window.abTestSessionInitialised = true;
    }
};

const run = () => {
    if (!localStorageCheck.isEnabled()) {
        return;
    }

    // We need to wait for VWO to properly initialise, that's why we push it in the queue
    window._vis_opt_queue = window._vis_opt_queue || []; // eslint-disable-line no-underscore-dangle
    window._vis_opt_queue.push(updateSession); // eslint-disable-line no-underscore-dangle
};

export default run;
