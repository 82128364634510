import axios from 'axios';
import { captureException, withScope } from '@sentry/browser';

export default class AppLoader {
    constructor(appUrl, scriptType = 'text/javascript') {
        this.appUrl = appUrl;
        this.scriptType = scriptType;
        this.urlsJs = null;
        this.urlsCss = null;
        this.getDocument();
    }

    extractUrlsFromHtml(html, mode) {
        if (!html) {
            return [];
        }
        const router = {
            js: {
                tagName: 'script',
                attribute: 'src',
            },
            css: {
                tagName: 'link',
                attribute: 'href',
            },
        };

        const urls = [];
        const parser = new DOMParser();
        const parsed = parser.parseFromString(
            html,
            'text/html',
        );

        const elements = parsed.getElementsByTagName(router[mode].tagName);

        Array.from(elements).forEach((element) => {
            const url = element[router[mode].attribute];

            if (
                !urls.includes(url)
                && url.endsWith(mode)
            ) {
                urls.push(url);
            }
        });

        return urls;
    }

    getDocument() {
        axios.get(this.appUrl)
            .then((response) => {
                this.urlsJs = this.extractUrlsFromHtml(response?.data, 'js');
                this.urlsCss = this.extractUrlsFromHtml(response?.data, 'css');
                this.loaderJs(this.urlsJs);
                this.loaderCss(this.urlsCss);
            }).catch(() => {
                // Not handled for now
            });
    }

    loaderJs(urls) {
        urls.forEach((url) => {
            const script = document.createElement('script');
            script.src = url;

            /**
             * We can use 'module' and 'text/javascript' for script.type
             * Modern bundlers like Vite.js supports ES modules for their bundles.
             * So, we should use 'module' for script.type
             * For legacy cases we can use 'text/javascript'.
             */
            script.type = this.scriptType;
            document.getElementsByTagName('head')[0].appendChild(script);
        });
    }

    loaderCss(urls) {
        urls.forEach((url) => {
            const link = document.createElement('link');
            link.href = url;
            link.rel = 'stylesheet';
            link.type = 'text/css';
            document.getElementsByTagName('head')[0].appendChild(link);
        });
    }
}
