/**
 * Media queries utils to make it possible to provide different JS components with the current
 * page active breakpoint. Useful to adapt the behaviour of certain functionalities depending on
 * the viewport size.
 */
class MediaQueries {
    constructor() {
        this.breakpoints = [
            { name: 'sm', value: 640 },
            { name: 'md', value: 1024 },
            { name: 'lg', value: 1366 },
        ];
    }

    /**
     * Gets a breakpoint object
     * @param {string} breakpointName The breakpoint name
    * @return {Object|False} The breakpoint object, or false if none was found
     */
    getBreakpoint(breakpointName) {
        const breakpoint = this.breakpoints.filter(
            (currentBreakpoint) => currentBreakpoint.name === breakpointName,
        );

        if (breakpoint.length) {
            return breakpoint[0];
        }

        return false;
    }

    /**
     * Gets the next (larger) breakpoint object based on a current one
     * @param {Object} breakpoint Current breakpoint object
     * @return {Object|False} The next breakpoint, or false if none was found
     */
    getNextBreakpoint(breakpoint) {
        const currentIndex = this.breakpoints.indexOf(breakpoint);

        if (currentIndex === -1) {
            return false;
        }

        if (this.breakpoints.length < currentIndex + 1) {
            return false;
        }

        return this.breakpoints[currentIndex + 1];
    }

    /**
     * Will return true until device width matches breakpoint
     * @param {string} breakpointName The name of the breakpoint
     * @return {bool} True if matches
     */
    until(breakpointName) {
        const breakpoint = this.getBreakpoint(breakpointName);

        if (!breakpoint) {
            return false;
        }

        return window.matchMedia(`(max-width: ${breakpoint.value}px)`).matches;
    }

    /**
     * Will return true when device width matches, and is larger, than breakpint
     * @param {string} breakpointName The name of the breakpoint
     * @return {bool} True if matches
     */
    from(breakpointName) {
        const breakpoint = this.getBreakpoint(breakpointName);

        if (!breakpoint) {
            return false;
        }

        return window.matchMedia(`(min-width: ${breakpoint.value}px)`).matches;
    }

    /**
     * Will return true when the device width is at breakpoint but smaller than the next breakpoint
     * @param {string} breakpointName The name of the breakpoint
     * @return {bool} True if matches
     */
    to(breakpointName) {
        const breakpoint = this.getBreakpoint(breakpointName);

        if (!breakpoint) {
            return false;
        }

        const breakpointTo = this.getNextBreakpoint(breakpoint);

        if (!breakpointTo) {
            return false;
        }

        return window.matchMedia(`(min-width: ${breakpoint.value}px) and (max-width: ${breakpointTo.value - 1}px)`).matches;
    }
}

export default new MediaQueries();
