import MicroModal from 'micromodal';

export default class TrustPilot {
    constructor() {
        this.moveModals();
        this.handleClick();
    }

    handleClick() {
        const widgets = document.querySelectorAll('[data-trustpilot]');
        widgets.forEach((widgetEl) => {
            const readMoreLinkEl = widgetEl.querySelector('[data-trustpilot-modal-id]');
            readMoreLinkEl.addEventListener('click', (event) => {
                event.preventDefault();
                const { trustpilotModalId } = event.target.dataset;
                MicroModal.show(`modal-trustpilot-${trustpilotModalId}`);
            });
        });
    }

    /**
     * When the page loads, each TrustPilot review widget will contain its own modal box code.
     * Sadly, according to the way our carousel works internally, opening a modal box from within
     * the carousel context ends up displaying the modal inside the carousel, which is broken. This
     * is a JS + CSS issue, so the best way I found to tackle this in a clean way is to move all
     * modals to the end of the document's <body> when the page loads.
     */
    moveModals() {
        const moved = [];
        const modals = document.querySelectorAll('[id^="modal-trustpilot-"]');
        modals.forEach((el) => {
            if (moved.includes(el.id)) {
                /**
                 * Deduplicate, in case some modals have the same ID, only one will be moved, the
                 * rest will be removed. This can happen if a content editor decides to place
                 * multiple instances of the reviews carousel in the same page.
                 */
                el.remove();
            } else {
                document.body.appendChild(el);
                moved.push(el.id);
            }
        });
    }
}
