import MicroModal from 'micromodal';

export default function () {
    MicroModal.init({
        awaitOpenAnimation: true,
        awaitCloseAnimation: true,
        disableFocus: true,
        onClose(modal, element, event) {
            /**
             * Fixes https://github.com/ghosh/Micromodal/issues/324
             */
            event.preventDefault();
            event.stopPropagation();
        },
    });
}
