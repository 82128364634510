export default class VideoContent {
    constructor(videoUUID) {
        this.videoBlock = document.querySelector(`[data-video-content-block="${videoUUID}"]`);

        this.text2 = this.videoBlock.querySelector('[data-video-content-paragraph-more]');
        this.toggleButton = this.videoBlock.querySelector('[data-video-content-toggle]');
        if (this.toggleButton) {
            this.readMoreLabel = this.toggleButton.dataset.videoContentLabelReadMore;
            this.readLessLabel = this.toggleButton.dataset.videoContentLabelReadLess;
            this.load();
        }
    }

    load() {
        this.toggleButton.addEventListener(
            'click',
            (e) => {
                e.preventDefault();
                if (this.text2.style.display === 'none') {
                    this.toggleButton.innerHTML = this.readLessLabel;
                    this.toggleButton.classList.remove('video-content__button--more');
                    this.toggleButton.classList.add('video-content__button--less');
                    this.text2.style.display = 'inline';
                } else {
                    this.text2.style.display = 'none';
                    this.toggleButton.innerHTML = this.readMoreLabel;
                    this.toggleButton.classList.remove('video-content__button--less');
                    this.toggleButton.classList.add('video-content__button--more');
                }
            },
        );
    }
}
