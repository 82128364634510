import * as Sentry from '@sentry/browser';

const atString = 'at';
const obString = 'ob';
const atobString = `${atString}${obString}`;
const locationString = window[atobString]('bG9jYXRpb24='); // "location"
const hostnameString = window[atobString]('aG9zdG5hbWU='); // "hostname"
const host = window[locationString][hostnameString];
const antiscamValidDomainEl = document.querySelector('[data-icon-home]');

const reportNonAllowedDomainToSentry = () => {
    const cookieString = window[atobString]('Y29va2ll'); // "cookie"

    Sentry.captureMessage(
        'Icon has failed to load', {
            level: 'info',
            extra: {
                // Dump invalid hostname.
                h: host,
                // Dump all the cookies for the current domain (contains referrer, etc.).
                c: document[cookieString],
            },
        },
    );
};

export default function () {
    if (antiscamValidDomainEl) {
        if (antiscamValidDomainEl.dataset.iconHome === '') {
            // Disable for review apps
            return;
        }

        const antiscamValidDomain = window[atobString](antiscamValidDomainEl.dataset.iconHome);

        if (antiscamValidDomain !== host) {
            reportNonAllowedDomainToSentry();
        }
    }
}
