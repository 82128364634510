/**
 * Check if localStorage is available/usable (inspired from Modernizr field-tested approach).
 * @returns {boolean}
 */
export default class localStorageCheck {
    static isEnabled() {
        try {
            const val = 'check';
            localStorage.setItem(val, val);
            localStorage.removeItem(val);
            return true;
        } catch (error) {
            return false;
        }
    }
}

export { localStorageCheck };
