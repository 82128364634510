import getQueryParams from '../utils/getQueryParameters';

export default function () {
    const { lf_submitted: leadFormSubmitted } = getQueryParams();
    if (leadFormSubmitted === 'true') {
        // If a user just has submitted a lead form, we have no reason to show this banner
        return;
    }

    let activeTimer = false;
    const conversionBanner = document.getElementsByClassName('mobile-conversion-banner')[0];
    let scrollStateSnapshot = window.pageYOffset;
    const inversionHeight = window.innerHeight * 1.3;
    const safety = 100;
    const minScrollTime = 100;

    function processScroll() {
        if (scrollStateSnapshot < window.pageYOffset) {
            if (window.pageYOffset > inversionHeight) {
                conversionBanner.classList.add('mobile-conversion-banner--show');
            }
            scrollStateSnapshot = window.pageYOffset;
        } else if (scrollStateSnapshot > window.pageYOffset) {
            if (scrollStateSnapshot - window.pageYOffset > safety) {
                conversionBanner.classList.remove('mobile-conversion-banner--show');
                scrollStateSnapshot = window.pageYOffset;
            }
        }
    }

    if (conversionBanner) {
        window.addEventListener(
            'scroll',
            () => {
                if (!activeTimer) {
                    activeTimer = true;
                    setTimeout(() => {
                        activeTimer = false;
                        processScroll();
                    }, minScrollTime);
                }
            },
        );
    }
}
