import Cookies from 'js-cookie';
import { localStorageCheck } from '../utils/localStorageCheck';
import getQueryParams from '../utils/getQueryParameters';

export default function () {
    /**
     * Initiates tracking cookies.
     * All cookie keys stored in COOKIE_MAP will be used by the lead-form app and sent along the
     * lead data. If the visitor never becomes a lead, this information will be lost.
     */

    /**
     * Options for js-cookies (see: https://github.com/js-cookie/js-cookie)
     */
    const COOKIE_OPTIONS_BASE = {
        path: '/',
        domain: window.location.hostname,
    };

    const COOKIE_OPTIONS_GLOBAL = {
        ...COOKIE_OPTIONS_BASE,
        expires: 365, // General tracking cookies are valid for a year.
    };

    /**
     * All cookie keys we need to take care of.
     */
    const COOKIE_MAP = {
        thermondo__page_referrer__first_visit: null,
        thermondo__page_referrer__last_visit: null,
        thermondo__page_landing__first_visit: null,
        thermondo__page_landing__last_visit: null,
        thermondo__date__first_visit: null,
        thermondo__date__last_visit: null,
        thermondo__medium__first_visit: null,
        thermondo__medium__last_visit: null,
        thermondo__source__first_visit: null,
        thermondo__source__last_visit: null,
        thermondo__campaign__first_visit: null,
        thermondo__campaign__last_visit: null,
        thermondo__term__first_visit: null,
        thermondo__term__last_visit: null,
        thermondo__bundesland__first_visit: null,
        thermondo__bundesland__last_visit: null,
        google_analytics__gclid: null,
        user_device__is_mobile: null,
        user_device__user_agent: null,
        msclkid: null,
    };

    /**
     * Check if there is an active tracking session.
     *
     * @returns {bool}
     */
    const isTrackingSessionStarted = () => localStorageCheck.isEnabled()
        && sessionStorage.getItem('trackingSessionStarted') === 'true';

    /**
     * We store query params here, as it will be re-used across different methods.
     */
    const queryParams = getQueryParams();

    const setReferrer = () => {
        if (!Cookies.get('thermondo__page_referrer__first_visit')) {
            COOKIE_MAP.thermondo__page_referrer__first_visit = document.referrer || 'none';
        }

        /**
         * We want the referrer cookie to only be populated when entering the website. Then moving
         * from page to page should not change the cookie value.
         */
        if (
            document.referrer.indexOf(window.location.hostname) === -1
            && !isTrackingSessionStarted()
        ) {
            COOKIE_MAP.thermondo__page_referrer__last_visit = document.referrer || 'none';
        }
    };

    const setPageLanding = () => {
        if (!Cookies.get('thermondo__page_landing__first_visit')) {
            COOKIE_MAP.thermondo__page_landing__first_visit = window.location.href || 'none';
        }
        if (!isTrackingSessionStarted()) {
            COOKIE_MAP.thermondo__page_landing__last_visit = window.location.href || 'none';
        }
    };

    const setDate = () => {
        if (!Cookies.get('thermondo__date__first_visit')) {
            COOKIE_MAP.thermondo__date__first_visit = new Date().toString();
        }
        if (!isTrackingSessionStarted()) {
            COOKIE_MAP.thermondo__date__last_visit = new Date().toString();
        }
    };

    const setMedium = () => {
        if (!Cookies.get('thermondo__medium__first_visit')) {
            COOKIE_MAP.thermondo__medium__first_visit = queryParams.utm_medium || 'none';
        }
        if (!isTrackingSessionStarted()) {
            COOKIE_MAP.thermondo__medium__last_visit = queryParams.utm_medium || 'none';
        }
    };

    const setSource = () => {
        if (!Cookies.get('thermondo__source__first_visit')) {
            COOKIE_MAP.thermondo__source__first_visit = queryParams.utm_source || 'none';
        }
        if (!isTrackingSessionStarted()) {
            COOKIE_MAP.thermondo__source__last_visit = queryParams.utm_source || 'none';
        }
    };

    const setCampaign = () => {
        if (!Cookies.get('thermondo__campaign__first_visit')) {
            COOKIE_MAP.thermondo__campaign__first_visit = queryParams.utm_campaign || 'none';
        }
        if (!isTrackingSessionStarted()) {
            COOKIE_MAP.thermondo__campaign__last_visit = queryParams.utm_campaign || 'none';
        }
    };

    const setTerm = () => {
        if (!Cookies.get('thermondo__term__first_visit')) {
            COOKIE_MAP.thermondo__term__first_visit = queryParams.utm_term || 'none';
        }
        if (!isTrackingSessionStarted()) {
            COOKIE_MAP.thermondo__term__last_visit = queryParams.utm_term || 'none';
        }
    };

    const setState = () => {
        if (!Cookies.get('thermondo__bundesland__first_visit')) {
            COOKIE_MAP.thermondo__bundesland__first_visit = queryParams.utm_bundesland || 'none';
        }
        if (!isTrackingSessionStarted()) {
            COOKIE_MAP.thermondo__bundesland__last_visit = queryParams.utm_bundesland || 'none';
        }
    };

    const setGclid = () => {
        if (
            document.referrer.indexOf(window.location.hostname) === -1
            && !isTrackingSessionStarted()
        ) {
            COOKIE_MAP.google_analytics__gclid = queryParams.gclid || 'none';
        }
    };

    const setIsMobile = () => {
        const reg = new RegExp(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i);
        if (!isTrackingSessionStarted()) {
            COOKIE_MAP.user_device__is_mobile = reg.test(window.navigator.userAgent) ? 'mobile' : 'desktop';
        }
    };

    const setUserAgent = () => {
        if (!isTrackingSessionStarted()) {
            COOKIE_MAP.user_device__user_agent = window.navigator.userAgent || 'none';
        }
    };

    const setMsclkid = () => {
        if (!isTrackingSessionStarted()) {
            /**
             * We are temporarily using "msclkid" as a catch-all.
             *
             * See https://thermondo.atlassian.net/browse/MT-1545
             */
            COOKIE_MAP.msclkid = queryParams.msclkid // Microsoft Ads
                || queryParams.gclid // Google Ads
                || queryParams.fbclid // Facebook Ads
                || queryParams.OutbrainClickId // Outbrain
                || queryParams.tbclid // Taboola
                || queryParams.cto_pld // Criteo
                || 'none';
        }
    };

    /**
     * Set all values in COOKIE_MAP
     */
    setReferrer();
    setPageLanding();
    setDate();
    setMedium();
    setSource();
    setCampaign();
    setTerm();
    setState();
    setIsMobile();
    setUserAgent();
    setGclid();
    setMsclkid();

    /**
     * Set the cookies based on COOKIE_MAP
     */
    Object.keys(COOKIE_MAP).forEach(
        (item) => {
            if (COOKIE_MAP[item] !== null) {
                Cookies.set(item, COOKIE_MAP[item], COOKIE_OPTIONS_GLOBAL);
            }
        },
    );

    /**
     * Set a "trackingSessionStarted" flag in browser's session storage.
     */
    if (localStorageCheck.isEnabled() && !isTrackingSessionStarted()) {
        sessionStorage.setItem('trackingSessionStarted', true);
    }
}
