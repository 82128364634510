import axios from 'axios';
import { captureMessage, withScope } from '@sentry/browser';

/**
 * Check if website.*.css is available on CDN/Heroku. If not, collect some debug data and send it
 * to Sentry.
 * See https://thermondo.atlassian.net/browse/MT-1370
 */
export default async function () {
    if (
        window.TNS
        && window.TNS.config
        && window.TNS.config.cssUrl
    ) {
        const { cssUrl } = window.TNS.config;

        withScope(async (scope) => {
            scope.setTag('backend.endpoint', cssUrl);

            try {
                await axios.get(cssUrl);
            } catch (error) {
                if (error && error.response && error.response.status === 404) {
                    captureMessage('MT-1370 - Monitor for 404 on website.*.css');
                }
            }
        });
    }
}
