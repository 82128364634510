export default function () {
    let activeTimer = false;
    const ctaButton = document.getElementById('header__cta');
    const documentHeight = document.body.scrollHeight;
    const inversionHeight = documentHeight * 0.7;
    const minScrollTime = 200;

    const processScroll = () => {
        if (window.pageYOffset > inversionHeight) {
            ctaButton.classList.add('header--inverted');
        } else {
            ctaButton.classList.remove('header--inverted');
        }
    };

    if (ctaButton) {
        window.addEventListener(
            'scroll',
            () => {
                if (!activeTimer) {
                    activeTimer = true;
                    setTimeout(() => {
                        activeTimer = false;
                        processScroll();
                    }, minScrollTime);
                }
            },
        );
    }
}
