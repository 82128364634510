import validate from 'validate.js';
import MicroModal from 'micromodal';
import { captureException, withScope } from '@sentry/browser';

export default class PressMailingForm {
    constructor() {
        this.API_URL = 'https://info.thermondo.de/l/963753/2023-08-15/4dvwg/';

        this.form = document.querySelector('[data-press-mailing-section]');

        this.form.addEventListener(
            'submit',
            (ev) => {
                ev.preventDefault();
                this.handleFormSubmit(this.form);
            },
        );

        this.inputs = this.form.querySelectorAll('input');
        this.inputs.forEach((input) => {
            input.addEventListener('change', () => {
                const errors = validate(this.form, this.constraints) || {};
                this.handleClientErrorMessagesDisplay(input, errors[input.name]);
            });
        });

        this.submitButton = this.form.querySelector('button[type=submit]');

        this.constraints = {
            first_name: {
                presence: true,
            },
            last_name: {
                presence: true,
            },
            email: {
                presence: true,
                email: true,
            },
            terms: {
                inclusion: {
                    within: [true],
                },
            },
        };
    }

    handleClientErrorMessagesDisplay(input, hasError) {
        const parentEl = input.parentNode.parentNode;
        parentEl.classList.toggle('form-field-error__container', !!hasError);
    }

    updateButton(state) {
        this.submitButton.disabled = state;
        this.submitButton.classList.toggle('t-btn-is-loading', state);
    }

    async handleFormSubmit() {
        const errors = validate(this.form, this.constraints);
        this.checkForErrors(errors || {});

        if (!errors) {
            const formData = new FormData(this.form);
            const formObject = new URLSearchParams({
                'First Name': formData.get('first_name'),
                'Last Name': formData.get('last_name'),
                Email: formData.get('email'),
            });
            this.updateButton(true);

            try {
                const response = await fetch(this.API_URL, {
                    body: formObject,
                    method: 'post',
                    mode: 'no-cors',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                });
                await response.text();
                this.updateButton(false);
                this.clearForm();
                this.showSubscriptionMessage('success');
            } catch (error) {
                this.updateButton(false);
                this.showSubscriptionMessage('failure');
                withScope((scope) => {
                    scope.setTag('backend.endpoint', this.API_URL);
                    captureException(error);
                });
            }
        }
    }

    checkForErrors(errors) {
        this.inputs.forEach((input) => {
            this.handleClientErrorMessagesDisplay(input, errors[input.name]);
        });
    }

    clearForm() {
        this.inputs.forEach((input) => {
            if (input.type === 'checkbox') {
                // eslint-disable-next-line no-param-reassign
                input.checked = false;
            } else {
            // eslint-disable-next-line no-param-reassign
                input.value = '';
            }
        });
    }

    showSubscriptionMessage(status) {
        MicroModal.show('modal-press-form-success');
        const success = document.querySelector('[data-success-result]');
        const failure = document.querySelector('[data-failure-result]');
        if (status === 'success') {
            success.style.display = 'block';
            failure.style.display = 'none';
        } else {
            success.style.display = 'none';
            failure.style.display = 'block';
        }
    }
}
