import * as Sqrl from 'squirrelly';
import {
    titleTemplate,
    textTemplate,
    linkLabelTemplate, linkTargetTemplate,
} from '../templates/technology';

/**
 * TechnologyDesktop
 *
 * Responsible for handling the desktop version of the navigation.
 */
export default class TechnologyDesktop {
    constructor() {
        this.instanceEl = document.querySelector(
            '[data-technology-desktop]',
        );
        this.menuEls = this.instanceEl.querySelectorAll(
            '[data-technology-desktop-menu-key]',
        );

        /**
         * Placeholders (places where to render different menu template fragments).
         */
        this.titlePlaceholder = this.instanceEl.querySelector(
            '[data-technology-desktop-title-placeholder]',
        );
        this.textPlaceholder = this.instanceEl.querySelector(
            '[data-technology-desktop-text-placeholder]',
        );
        this.linkPlaceholder = this.instanceEl.querySelector(
            '[data-technology-desktop-link-placeholder]',
        );

        /**
         * Pre-select first item.
         */
        this.handleMenu(this.menuEls[0]);

        this.init();
    }

    init() {
        /**
         * Register general UI event listeners.
         */
        Array.prototype.forEach.call(
            this.menuEls,
            (el) => {
                el.addEventListener(
                    'click',
                    (event) => {
                        event.preventDefault();
                        this.handleMenu(event.target);
                    },
                );
            },
        );
    }

    /**
     * Open and initialize menu.
     */
    handleMenu(target) {
        const key = target.dataset.technologyDesktopMenuKey;

        this.titlePlaceholder.textContent = Sqrl.render(
            titleTemplate,
            {
                key,
            },
        );
        this.textPlaceholder.textContent = Sqrl.render(
            textTemplate,
            {
                key,
            },
        );
        this.linkPlaceholder.textContent = Sqrl.render(
            linkLabelTemplate,
            {
                key,
            },
        );
        this.linkPlaceholder.setAttribute(
            'href',
            Sqrl.render(
                linkTargetTemplate,
                {
                    key,
                },
            ),
        );

        this.menuEls.forEach((menuEl) => {
            menuEl.classList.remove('active');
        });

        target.classList.add('active');
    }
}
