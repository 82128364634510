import MicroModal from 'micromodal';
import IndicationPriceCalculator from './IndicationPriceCalculator';

export default class FlexRateCalculator extends IndicationPriceCalculator {
    constructor(id) {
        super(id);

        this.resultElement = this.formElement.querySelector('[data-result-rate]');
        this.updatePrice();

        const modalOpen = this.formElement.querySelector('[data-modal-calculator-open]');
        modalOpen.addEventListener('click', () => MicroModal.show(`modal-calculator-${id}`));
    }

    updatePrice() {
        const annualInterestRate = 6.49;
        const indicationPrice = super.updatePrice();
        const loanTermInMonths = this.formElement.querySelector('[name=duration_in_month]').value;

        // Format and display the monthly cost
        this.resultElement.innerHTML = Intl.NumberFormat(
            'de-DE',
            {
                style: 'currency',
                currency: 'EUR',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            },
        ).format(this.calculateMonthlyRate(indicationPrice, annualInterestRate, loanTermInMonths));
    }

    calculateMonthlyRate(indicationPrice, annualInterestRate, loanTermInMonths) {
        // Convert annual interest rate to monthly interest rate
        const monthlyInterestRate = annualInterestRate / 12 / 100;

        // Calculate the monthly payment using the formula https://bettersheets.co/formulas/pmt
        // eslint-disable-next-line max-len,no-restricted-properties,no-mixed-operators
        return indicationPrice * monthlyInterestRate / (1 - Math.pow(1 + monthlyInterestRate, -loanTermInMonths));
    }
}
