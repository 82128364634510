import axios from 'axios';
import MicroModal from 'micromodal';
import { captureException, withScope } from '@sentry/browser';
import validate from 'validate.js';

export default class Newsletter {
    constructor() {
        this.LIANA_API_URL = 'https://info.thermondo.de/account/?ajax';
        this.LIANA_MAILING_LIST_ID = 1980543;
        this.LIANA_CONSENT = '1454-11-de';

        this.form = document.querySelector(
            '[data-form-newsletter]',
        );

        this.formInputs = this.form.querySelectorAll(
            'input',
        );

        this.emailFieldEl = this.form.querySelector(
            'input[type="email"]',
        );

        this.termsInputEl = this.form.querySelector(
            '.checkbox',
        );

        this.submitBtn = this.form.querySelector(
            'button[type="submit"]',
        );

        this.modalContents = document.querySelectorAll(
            '[data-form-newsletter-modal-content]',
        );

        this.constraints = {
            email: {
                presence: true,
                email: true,
            },
            terms: {
                inclusion: {
                    within: [true],
                },
            },
        };

        this.init();
    }

    init() {
        this.formInputs.forEach((input) => {
            input.addEventListener('change', () => {
                const errors = validate(this.form, this.constraints) || {};
                this.handleClientErrorMessagesDisplay(input, errors[input.name]);
            });
        });

        this.emailFieldEl.addEventListener(
            'input',
            () => {
                this.showTerms();
            },
        );

        this.form.addEventListener(
            'submit',
            (e) => {
                e.preventDefault();
                this.handleFormSubmit(this.form);
            },
        );
    }

    clearForm() {
        this.emailFieldEl.value = '';
        this.termsInputEl.querySelector('input').checked = false;
    }

    showTerms() {
        if (this.termsInputEl.style.display === 'none') {
            this.termsInputEl.style.display = 'block';
        }
    }

    handleFormSubmit() {
        const errors = validate(this.form, this.constraints);
        this.checkForErrors(errors || {});

        if (!errors) {
            this.isLoading(true);

            const params = new URLSearchParams();

            params.append('email', this.emailFieldEl.value);
            params.append('join', this.LIANA_MAILING_LIST_ID);
            params.append('consent', this.LIANA_CONSENT);


            axios.post(
                this.LIANA_API_URL,
                params,
            )
            .then(() => {
                this.showModal('success');
                this.clearForm();
            })
            .catch(() => {
                this.showModal('error-generic');
            })
            .finally(() => {
                this.isLoading(false);
            });
        }
    }

    showModal(status) {
        this.modalContents.forEach((modalContent) => {
            if (modalContent.dataset.formNewsletterModalContent === status) {
                // eslint-disable-next-line no-param-reassign
                modalContent.style.display = 'block';
            } else {
                // eslint-disable-next-line no-param-reassign
                modalContent.style.display = 'none';
            }
        });

        MicroModal.show('modal-newsletter-form');
    }

    isLoading(state) {
        if (state) {
            this.submitBtn.classList.add('t-btn-is-loading');
        } else {
            this.submitBtn.classList.remove('t-btn-is-loading');
        }

        this.submitBtn.disabled = state;
    }

    handleClientErrorMessagesDisplay(input, hasError) {
        const parentEl = input.parentNode.parentNode;

        if (hasError) {
            parentEl.classList.add('form-field-error__container');
        } else {
            parentEl.classList.remove('form-field-error__container');
        }
    }

    checkForErrors(errors) {
        // Check fields
        this.formInputs.forEach((input) => {
            this.handleClientErrorMessagesDisplay(input, errors[input.name]);
        });
    }
}
