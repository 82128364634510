import axios, { AxiosHeaders } from 'axios';
import validate from 'validate.js';
import { eT } from './EventTracking';

export default class ZipCodeForm {
    constructor() {
        this.API_URL = `${window.TNS.config.backend.url}/api/zipcode`;
        this.TOKEN = window.TNS.config.backend.token;

        this.formErrorGeneral = 'Bitte geben Sie eine gültige deutsche Postleitzahl an.';
        this.formErrorDigits = 'Bitte geben Sie eine gültige 5-stellige deutsche Postleitzahl an.';

        this.el = document.querySelector('[data-small-search-block]');
        this.clientError = this.el.querySelector('.form-field-error__text');
        this.serverError = this.el.querySelector('.form-info-box--error');
        this.headline = this.el.querySelector('.zipcode-search-small__headline');
        this.status = this.el.querySelector('.zipcode-search-small__status');

        this.input = this.el.querySelector('input');
        this.submitButton = this.el.querySelector('button[type=submit]');
        this.form = this.el.querySelector('form');
        this.form.addEventListener(
            'submit',
            async (ev) => {
                ev.preventDefault();
                await this.handleFormSubmit();
            },
        );

        this.constraints = {
            zipcode: {
                presence: {
                    message: this.formErrorDigits,
                },
                format: {
                    pattern: /[0-9]{5}/,
                    message: this.formErrorDigits,
                },
            },
        };
    }

    updateResult(success) {
        if (success) {
            this.el.classList.add('zipcode-search-small--success');
            this.headline.innerHTML = 'Ja, wir sind auch bei Ihnen vor Ort verfügbar!';
        } else {
            this.el.classList.add('zipcode-search-small--fail');
            this.headline.innerHTML = 'Schade!';
        }

        eT({
            event: success ? 'zipcode_operated_area' : 'zipcode_white_area',
        });

        const icon = `<i class="t-website-map-${success ? 'success' : 'fail'}"></i>`;
        this.status.innerHTML = `${icon} ${this.input.value}`;
    }

    updateButton(state) {
        this.submitButton.disabled = state;
        this.submitButton.classList.toggle('t-btn-is-loading', state);
    }

    updateClientError(error) {
        this.clientError.innerHTML = error || '';
        this.form.classList.toggle('form-field-error__container', !!error);
    }

    updateServerError(state) {
        if (!state) {
            this.serverError.style.display = 'none';
        } else {
            this.serverError.style.display = 'block';

            eT({
                event: 'zipcode_backend_error',
            });
        }
    }

    async handleFormSubmit() {
        this.updateServerError(false);

        const val = validate(this.form, this.constraints, {
            fullMessages: false,
        });

        this.updateClientError((val && val.zipcode) ? val.zipcode.join(' ') : null);

        if (!val) {
            this.updateButton(true);

            eT({
                event: 'zipcode_search',
                zipcode: this.input.value,
            });

            const headers = new AxiosHeaders({
                Authorization: `Token ${this.TOKEN}`,
            });

            await axios.get(`${this.API_URL}/${this.input.value}/`, {
                headers,
            }).then((response) => {
                const { operated_by_thermondo: success } = response.data;
                this.updateResult(success);
            }).catch((error) => {
                if (error.response && error.response.status === 404) {
                    // 404 means zip code could not be found in our database
                    this.updateClientError(this.formErrorGeneral);
                } else {
                    this.updateServerError(true);
                }

                this.updateButton(false);
            });
        }
    }
}
