const listFilterItemsModalTemplate = `
    <form class="jobs-list__modal-filter-list">
        <div class="checkbox checkbox--highlighted">
            <input type="checkbox" name="job-filter" id="job-filter-{{ it.type }}-all"
                   data-list-filter-modal-item-{{ it.type }}="all" aria-selected="true" checked>
            <label for="job-filter-{{ it.type }}-all" class="no-tap-highlight">
                Alle
            </label>
        </div>
        {{ @each(it.items) => item }}
            <div class="checkbox">
                <input type="checkbox" name="job-filter" id="job-filter-{{ it.type }}-{{ item.id }}"
                       data-list-filter-modal-item-{{ it.type }}="{{ item.id }}" aria-selected="false">
                <label for="job-filter-{{ it.type }}-{{ item.id }}" class="no-tap-highlight">
                    {{ item.label }}
                </label>
            </div>
        {{ /each }}
    </form>
`;

const listFilterScrollToButtonTemplate = `
    <div class="list-filter__scrollto-button"
         data-list-filter-scrollto-button
         data-scroll-to="list-filter-title"
         data-scroll-to-offset="100">
        <i class="t-website-filter"></i>
    </div>
`;

export {
    listFilterItemsModalTemplate,
    listFilterScrollToButtonTemplate,
};
