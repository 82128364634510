import * as Sentry from '@sentry/browser';
import Swiper from 'swiper';
import { Navigation, Autoplay } from 'swiper/modules';

/**
 * Possible options to define in the template:
 *
 * Space between each slide item, in px (mandatory):
 *   data-swiper-space-between-sm-down="10"
 *   data-swiper-space-between-sm-up="20"
 *   data-swiper-space-between-md-up="24"
 *   data-swiper-space-between-lg-up="32"
 */
export default function () {
    /** @type NodeListOf<HTMLElement> */
    const carouselEls = document.querySelectorAll('.swiper');

    carouselEls.forEach((carouselEl) => {
        const spaceBetweenFallback = 24;

        const options = {
            spaceBetweenSmDown: carouselEl.dataset.swiperSpaceBetweenSmDown,
            spaceBetweenSmUp: carouselEl.dataset.swiperSpaceBetweenSmUp,
            spaceBetweenMdUp: carouselEl.dataset.swiperSpaceBetweenMdUp,
            spaceBetweenLgUp: carouselEl.dataset.swiperSpaceBetweenLgUp,
        };

        Object.keys(options).forEach((key) => {
            if (typeof options[key] !== 'string') {
                options[key] = spaceBetweenFallback;
                Sentry.captureMessage(
                    'Missing or wrong data-swiper-space-between-* attribute',
                    {
                        level: 'error',
                    },
                );
            }
        });

        let autoplayOptions = false;
        if (carouselEl.dataset.swiperAutoplayDelay) {
            autoplayOptions = {
                delay: carouselEl.dataset.swiperAutoplayDelay,
            };
        }

        // eslint-disable-next-line no-new
        new Swiper(carouselEl, {
            modules: [
                Navigation,
                Autoplay,
            ],
            navigation: {
                prevEl: '.swiper-button-prev',
                nextEl: '.swiper-button-next',
            },
            loop: !!carouselEl.dataset.swiperLoop,
            speed: carouselEl.dataset.swiperSpeed || 300,
            slidesPerView: 'auto',
            spaceBetween: options.spaceBetweenSmDown,
            autoplay: autoplayOptions,
            breakpoints: {
                576: {
                    spaceBetween: options.spaceBetweenSmUp,
                },
                768: {
                    spaceBetween: options.spaceBetweenMdUp,
                },
                1024: {
                    spaceBetween: options.spaceBetweenLgUp,
                },
            },
        });
    });
}
