/**
 * Helper, returns all query params from a URL.
 *
 * @returns {array}
 */
export default function() {
    function decode(str) {
        return window.decodeURIComponent(str).replace(/\+/g, ' ');
    }
    const queryParams = {};
    const args = window.location.search.replace(/^\?/, '').split('#')[0].split('&');
    for (let i = 0; i < args.length; i += 1) {
        const d = args[i].split('=');
        if (d[0] && d[1]) {
            queryParams[decode(d[0])] = decode(d[1]);
        }
    }

    return queryParams;
}
