import Clipboard from 'clipboard/dist/clipboard';

export default function () {
    const copyToClipboardElems = document.querySelectorAll('[data-clipboard-text]');

    copyToClipboardElems.forEach((copyToClipboardElem) => {
        const clipboard = new Clipboard(copyToClipboardElem);

        clipboard.on('success', () => {
            // eslint-disable-next-line no-param-reassign
            copyToClipboardElem
                .getElementsByClassName('job-sharing-buttons__label')[0]
                .innerHTML = 'Link kopiert <i class="t-website-check"></i>';
        });
    });
}
