export default function () {
    let activeTimer = false;
    const header = document.getElementsByClassName('header__wrapper')[0];
    const minScrollTime = 20;

    const processScroll = () => {
        if (window.pageYOffset > 0) {
            header.classList.add('header__wrapper--transparency');
        } else {
            header.classList.remove('header__wrapper--transparency');
        }
    };

    if (header) {
        window.addEventListener(
            'scroll',
            () => {
                if (!activeTimer) {
                    activeTimer = true;
                    setTimeout(() => {
                        activeTimer = false;
                        processScroll();
                    }, minScrollTime);
                }
            },
        );
    }
}
