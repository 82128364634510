import validate from 'validate.js';
import { captureException, withScope } from '@sentry/browser';
import { eT } from './EventTracking';

export default class CustomerReferralEmail {
    constructor() {
        this.customerReferral = window.TNS.config.customerReferral;
        this.API_URL = this.customerReferral.apiUrl;
        this.form = document.querySelector('[data-customer-referral_email]');

        this.form.addEventListener(
            'submit',
            (ev) => {
                ev.preventDefault();
                this.handleFormSubmit(this.form);
            },
        );

        this.inputs = this.form.querySelectorAll('input');
        this.inputs.forEach((input) => {
            input.addEventListener('change', () => {
                const errors = validate(this.form, this.constraints) || {};
                this.handleClientErrorMessagesDisplay(input, errors[input.name]);
            });
        });

        this.submitButton = this.form.querySelector('button[type=submit]');

        this.constraints = {
            salutation: {
                presence: true,
            },
            first_name: {
                presence: true,
            },
            last_name: {
                presence: true,
            },
            email: {
                presence: true,
                email: true,
            },
            acceptedTerms: {
                inclusion: {
                    within: [true],
                },
            },
        };
    }

    handleClientErrorMessagesDisplay(input, hasError) {
        const parentEl = input.parentNode.parentNode;
        parentEl.classList.toggle('form-field-error__container', !!hasError);
    }

    isButtonLoadingState(state) {
        this.submitButton.disabled = state;
        this.submitButton.classList.toggle('t-btn-is-loading', state);
    }

    async handleFormSubmit() {
        const errors = validate(this.form, this.constraints);
        this.checkForErrors(errors || {});

        if (!errors) {
            this.isButtonLoadingState(true);
            await this.getReferralId();
        }
    }

    async getReferralId() {
        const formData = new FormData(this.form);
        const formObject = {
            salutation: formData.get('salutation'),
            first_name: formData.get('first_name'),
            last_name: formData.get('last_name'),
            email: formData.get('email'),
            accepted_terms: true,
        };
        try {
            const response = await fetch(this.API_URL, {
                body: JSON.stringify(formObject),
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const referralID = await response.json();
            eT({
                event: 'referral_registration_success',
            });
            window.location.href = `${this.customerReferral.resultPage}?referral_id=${referralID}`;
            this.isButtonLoadingState(false);
        } catch (error) {
            this.isButtonLoadingState(false);
            withScope((scope) => {
                scope.setTag('backend.endpoint', this.API_URL);
                captureException(error);
            });
        }
    }

    checkForErrors(errors) {
        this.inputs.forEach((input) => {
            this.handleClientErrorMessagesDisplay(input, errors[input.name]);
        });
    }
}
