import { createPopper } from '@popperjs/core/lib/popper-lite';
import arrow from '@popperjs/core/lib/modifiers/arrow';
import offset from '@popperjs/core/lib/modifiers/offset';
import enterView from 'enter-view';
import MediaQueries from '../utils/mediaQueries';

export default function () {
    const popoverTriggers = document.querySelectorAll('[data-popover-trigger]');

    popoverTriggers.forEach((referenceElement) => {
        const popoverElement = document.getElementById(
            `popover-for-${referenceElement.dataset.popoverTrigger}`,
        );

        if (!popoverElement) {
            return;
        }

        const popoverOptions = {
            placement: MediaQueries.until('sm') ? 'top' : 'right',
            modifiers: [
                arrow,
                offset,
                {
                    name: 'offset',
                    options: {
                        /**
                         * Ensure the second offset value is equal to the "$popover-arrow-size"
                         * SCSS variable.
                         */
                        offset: [0, 20],
                    },
                },
            ],
        };

        let popoverSeenAndClosedByUser = false;

        enterView({
            selector: [referenceElement],
            enter(el) {
                /**
                 * The user shouldn't see the popup again if it has been closed.
                 *
                 * TODO: The current implementation works as long as the page is not reloaded. In
                 * the future we will revisit this behaviour and use cookies and/or local storage.
                 */
                if (popoverSeenAndClosedByUser) {
                    return;
                }

                popoverSeenAndClosedByUser = true;

                // Create the popover instance.
                createPopper(
                    el,
                    popoverElement,
                    popoverOptions,
                );

                // Display it.
                popoverElement.setAttribute('data-show', '');

                // Handle popover close button.
                popoverElement
                    .querySelector('.popover__close')
                    .addEventListener(
                        'click',
                        () => {
                            popoverElement.removeAttribute('data-show');
                        },
                    );
            },
            // Trigger the popover only when the referenceElement reaches the middle of the screen.
            offset: 0.5,
        });
    });
}
