import * as Sentry from '@sentry/browser';
import { scrollTo } from 'scroll-js';

export default function () {
    const isStickyHeader = document.querySelector('[data-sticky-header]');
    const btns = [...document.querySelectorAll('[data-scroll-to]')];
    btns.forEach((el) => {
        el.addEventListener(
            'click',
            (e) => {
                e.preventDefault();
                const scrollToEl = document.getElementById(el.dataset.scrollTo);
                const offset = !isStickyHeader ? 20 : el.dataset.scrollToOffset || 0;
                const offsetLg = !isStickyHeader ? 20 : el.dataset.scrollToOffsetLg || offset;
                const isLg = window.innerWidth >= 1024;

                if (scrollToEl) {
                    scrollTo(
                        document.body,
                        {
                            behavior: 'smooth',
                            top: scrollToEl.offsetTop - (isLg ? offsetLg : offset),
                        },
                    );
                } else {
                    Sentry.captureMessage(
                        `ScrollTo - No element found with ID "${el.dataset.scrollTo}"`,
                        {
                            level: 'error',
                        },
                    );
                }
            },
        );
    });
}
