import * as Sqrl from 'squirrelly';
import {
    listFilterItemsModalTemplate,
    listFilterScrollToButtonTemplate,
} from '../templates/listFilter';

export default class ListFilter {
    constructor(type) {
        this.type = type;
        this.capitalizedType = type[0].toUpperCase() + type.substring(1).toLowerCase();
        this.activeFilters = ['all'];
        this.activeLabels = ['Alle'];

        this.listItems = document.querySelectorAll(`[data-list-filter-item-id-${this.type}]`);
        this.selectedItems = document.querySelector(`[data-list-filter-selected-items-${this.type}]`);
        this.modalContentPlaceholder = document.querySelector(`[data-list-filter-modal-content-${this.type}]`);

        this.init();
    }

    init() {
        this.updateEmptyMessage();
        this.injectScrollToButton();
        this.renderModalFilterItems();

        const boundRunFilter = this.runFilter.bind(this);
        this.listFilterControlItems = document.querySelectorAll(`[data-list-filter-modal-item-${this.type}]`);
        this.listFilterControlItems.forEach((item) => {
            item.addEventListener(
                'change',
                boundRunFilter,
            );
        });

        /**
         * Close the related popover if clicking on the button, as the user is now aware of it.
         */
        const modalJobFilterTriggerElement = document.querySelectorAll('[data-popover-trigger="modal-job-filter"]');

        const closePopover = () => {
            const modalJobFilterPopoverElement = document.getElementById('popover-for-modal-job-filter');
            if (modalJobFilterPopoverElement) {
                modalJobFilterPopoverElement.removeAttribute('data-show');
            }
        };

        modalJobFilterTriggerElement.forEach((item) => {
            item.addEventListener(
                'click',
                closePopover,
            );
        });

        this.selectedItems.innerHTML = this.activeLabels;
    }

    /**
     * Inject "scroll-to" button into the DOM.
     * This button will only appears on the page when the list is visible.
     */
    injectScrollToButton() {
        let scrollToButtonEl = document.querySelector('[data-list-filter-scrollto-button]');

        /**
         * We do not want to inject the button if it already exists. Another ListFilter instance
         * might already have injected one, in case of multiple filters.
         */
        if (scrollToButtonEl) {
            return;
        }

        scrollToButtonEl = document.createElement('div');
        scrollToButtonEl.innerHTML = Sqrl.render(
            listFilterScrollToButtonTemplate,
            {},
        );
        document.body.appendChild(scrollToButtonEl);

        /**
         * Show/Hide handling
         */
        const handleIntersection = (entries) => {
            entries.forEach(entry => {
                if (entry.intersectionRatio > 0) {
                    scrollToButtonEl.classList.remove('hide');
                } else {
                    scrollToButtonEl.classList.add('hide');
                }
            });
        };

        const listFilterContainer = document.querySelector('[data-list-filter-container]');
        const observerContainer = new IntersectionObserver(handleIntersection);

        observerContainer.observe(listFilterContainer);
    }

    /**
     * Renders filter options in respective modal box.
     */
    renderModalFilterItems() {
        const items = [];
        const added = [];

        /**
         * Finding all unique list filter items...
         */
        Array.prototype.forEach.call(
            this.listItems,
            (listItem) => {
                const id = listItem.dataset[`listFilterItemId${this.capitalizedType}`];
                const label = listItem.dataset[`listFilterItemLabel${this.capitalizedType}`];

                if (added.includes(id)) {
                    return;
                }

                items.push({
                    id,
                    label,
                });

                added.push(id);
            },
        );

        /**
         * ...and adding them to the modal box.
         */
        this.modalContentPlaceholder.innerHTML = Sqrl.render(
            listFilterItemsModalTemplate,
            {
                type: this.type,
                items,
            },
        );
    }

    /**
     * Updates everything that is UI related.
     * - Adds/removes selected items in filter button to give the user a feedback
     * on currently selected options.
     * - Handles checkboxes state.
     */
    updateUiState(event) {
        const selectedValue = event.target.dataset[`listFilterModalItem${this.capitalizedType}`];
        const selectedLabel = event.target.parentNode.getElementsByTagName('label')[0].innerHTML;

        if (selectedValue === 'all') {
            this.activeFilters = ['all'];
            this.activeLabels = ['Alle'];

            // Enforce checked state if clicked when already checked.
            if (!event.target.checked) {
                event.target.checked = true;
            }

            this.listFilterControlItems.forEach((listFilterControlItem, i) => {
                if (i > 0) {
                    listFilterControlItem.checked = false;
                }
            });
        } else if (event.target.checked) {
            // Update active filters state
            this.activeLabels.push(selectedLabel.trim());
            this.activeFilters.push(selectedValue);
            this.activeLabels = this.activeLabels.filter(item => item !== 'Alle');
            this.activeFilters = this.activeFilters.filter(item => item !== 'all');

            // Uncheck "Alle"
            this.listFilterControlItems[0].checked = false;
        } else {
            this.activeLabels = this.activeLabels.filter(item => item !== selectedLabel.trim());
            this.activeFilters = this.activeFilters.filter(item => item !== selectedValue);

            // If no item is selected, automatically check "all".
            if (this.activeFilters.length === 0) {
                this.activeFilters = ['all'];
                this.activeLabels = ['Alle'];
                this.listFilterControlItems[0].checked = true;
            }
        }

        // Update filter button with current selection.
        this.selectedItems.innerHTML = this.activeLabels.join(', ');
    }

    /**
     * Add/Remove "no position available" message where needed.
     * Only applies if the list has "groups" (.jobs-list__group).
     */
    updateEmptyMessage() {
        const jobsGroups = document.querySelectorAll('.jobs-list__group');

        jobsGroups.forEach((jobsGroup) => {
            const visibleItemsInJobsGroup = jobsGroup.querySelectorAll(
                '[data-list-filter-item-id-department]:not(.list-filter--hide)',
            );
            const emptyGroupMessage = jobsGroup.querySelector('.jobs-list__empty');

            if (visibleItemsInJobsGroup.length > 0) {
                emptyGroupMessage.classList.add('jobs-list__empty--hide');
            } else {
                emptyGroupMessage.classList.remove('jobs-list__empty--hide');
            }
        });
    }

    /**
     * Process the list based on inputs provided.
     */
    runFilter(event) {
        const selectedValue = event.target.dataset[`listFilterModalItem${this.capitalizedType}`];

        this.updateUiState(event);

        if (selectedValue === 'all') {
            this.listItems.forEach((listFilterResultItem) => {
                listFilterResultItem.classList.remove('list-filter--hide');
            });
        } else {
            this.listItems.forEach((listFilterResultItem) => {
                const itemId = listFilterResultItem.dataset[`listFilterItemId${this.capitalizedType}`];
                if (this.activeFilters.includes(itemId)) {
                    listFilterResultItem.classList.remove('list-filter--hide');
                } else {
                    listFilterResultItem.classList.add('list-filter--hide');
                }
            });

            if (this.activeFilters.length === 1 && this.activeFilters[0] === 'all') {
                this.listItems.forEach((listFilterResultItem) => {
                    listFilterResultItem.classList.remove('list-filter--hide');
                });
            }
        }

        this.updateEmptyMessage();
    }
}
