export default class IndicationPriceCalculator {
    /**
    * @param {Element|undefined} formElement
    */
    formElement

    /**
    * @type {Object<string, Object<string, number>>} prices - prices[buildingHeatedArea][waterHeating]
    */

    static prices = {
        '0_100': {
            'yes': {
                'price': 9200,
                'kw': 5
            },
            'no': {
                'price': 8500,
                'kw': 5
            }
        },
        '101_150': {
            'yes': {
                'price': 10400,
                'kw': 7
            },
            'no': {
                'price': 8780,
                'kw': 7
            }
        },
        '151_200': {
            'yes': {
                'price': 12100,
                'kw': 9
            },
            'no': {
                'price': 9800,
                'kw': 9
            }
        },
        '201_300': {
            'yes': {
                'price': 13900,
                'kw': 10
            },
            'no': {
                'price': 11900,
                'kw': 10
            }
        }
    }

    constructor(id) {
        this.formElement = document.querySelector(`[data-calculator-id="${id}"]`)
        this.formElement.addEventListener('change', this.updatePrice.bind(this))
    }

    updatePrice() {
        const priceElement = this.formElement.querySelector('[data-result-price]')
        const kwElement = this.formElement.querySelector('[data-result-kw]');
        const buildingHeatedArea = this.formElement.querySelector('[name=building_heated_area]').value
        const waterHeating = this.formElement.querySelector('[name=water_heating]').value

        const { price, kw } = IndicationPriceCalculator.prices[buildingHeatedArea][waterHeating];

        priceElement.innerHTML = Intl.NumberFormat(
            'de-DE',
            {
                style: 'currency',
                currency: 'EUR',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
            }
        ).format(price);

        kwElement.innerHTML = `${kw} kW`;
    }
}
