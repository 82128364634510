import Handorgel from 'handorgel';

export default function () {
    const accordions = [...document.querySelectorAll('.handorgel')];

    accordions.forEach((accordionEl) => {
        // eslint-disable-next-line no-new
        new Handorgel(accordionEl, {
            multiSelectable: false,
        });
    });
}
