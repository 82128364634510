import getQueryParams from '../utils/getQueryParameters';
import { sessionStorageEnabled } from '../utils/sessionStorageCheck';

export default function () {
    const showBadge = () => {
        const badges = [
            ...document.querySelectorAll('.customer-referral-badge'),
        ];

        badges.forEach((badge) => {
            // eslint-disable-next-line no-param-reassign
            badge.classList.add('customer-referral-badge--show');
        });
    };

    if (sessionStorageEnabled()) {
        const referralId = getQueryParams().referral_id;

        if (referralId) {
            sessionStorage.setItem('referralId', referralId);
            showBadge();
        }
    }
}
