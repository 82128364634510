import Cookies from 'js-cookie';
import ResizeObserver from 'resize-observer-polyfill';

export default function () {
    const COOKIE_OPTIONS = {
        path: '/',
        expires: 1 / 4, // a quarter of a day -> 6 hours
        domain: window.location.hostname,
    };

    const { body } = document;
    const isHome = body.classList.contains('home');
    const headerDefault = document.querySelector('.header__wrapper');
    const headerLight = document.querySelector('.header-light__wrapper');
    const banner = document.querySelector('[data-notification-banner]');

    const adjustHeaderPosition = () => {
        const header = headerDefault || headerLight;

        if (isHome) {
            /**
             * The home is particular, as on desktop (large), there is no padding-top, the hero
             * goes behind the navigation, that is transparent.
             *
             * Only on mobile and tablet, we want have the header included in the padding-top
             * value.
             */
            if (window.innerWidth < window?.TNS?.config?.breakpoints?.lg) {
                body.style.paddingTop = `${header.offsetHeight + banner.offsetHeight}px`;
            } else {
                body.style.paddingTop = `${banner.offsetHeight}px`;
            }
        } else {
            body.style.paddingTop = `${header.offsetHeight + banner.offsetHeight}px`;
        }

        header.style.top = `${banner.offsetHeight}px`;
    };

    const observer = new ResizeObserver(() => {
        adjustHeaderPosition();
    });

    const show = () => {
        banner.style.display = 'block';
        adjustHeaderPosition();
    };

    const hide = () => {
        banner.style.display = 'none';
        adjustHeaderPosition();
        observer.unobserve(body);
        body.style.removeProperty('padding-top');
    };

    const setSeenCookie = () => {
        Cookies.set(
            'notification-banner',
            'seen',
            COOKIE_OPTIONS,
        );
    };

    const init = () => {
        if (!banner || Cookies.get('notification-banner')) {
            return;
        }

        observer.observe(body);

        show(banner);

        const closeBtn = banner.querySelector('[data-notification-banner-close]');

        closeBtn.addEventListener(
            'click',
            () => {
                setSeenCookie();
                hide(banner);
            },
        );
    };

    init();
}
